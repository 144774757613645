import { useQuery } from "@apollo/client";
import { GET_PROGRAM_V2_OVERVIEW } from "graphql/queries";

export default ({ variables, options }) => {
  const { data, loading, error } = useQuery(GET_PROGRAM_V2_OVERVIEW, {
    variables,
    ...options,
  });

  const programsV2Data = data?.getProgramV2;

  return [programsV2Data, loading, error];
};
