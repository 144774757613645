import React, { useMemo } from "react";
import { useParams } from "react-router";
import useGetProgramsV2 from "hooks/ProgramsV2/useGetProgramsV2";
import PlayerWithControls from "../../../components/ResponsiveVideoPlayer/PlayerWithControls";

const TrailerPlayer = () => {
  const { slug } = useParams();
  const [programsV2Data] = useGetProgramsV2({
    variables: { slug },
    options: { fetchPolicy: "cache-first" },
  });
  const trailerUrl = useMemo(() => {
    return programsV2Data?.content?.assets?.previewVideoURL;
  }, [programsV2Data]);

  return <PlayerWithControls url={trailerUrl} autoplay skipIncrement={10} />;
};

export default TrailerPlayer;
